@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #f5f5f5;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
        "Malgun Gothic", sans-serif;
}

main {
    flex: 1;
    display: "flex";
    flex-direction: "column";
    align-items: "center";
}

/*
::-webkit-scrollbar {
    background: transparent;
    width: 0.5rem;
    height: 0.5rem;
}
::-webkit-scrollbar-track {
    background: transparent;
    width: 0;
    height: 0;
    -webkit-box-shadow: none;
}
*/

::-webkit-scrollbar-thumb {
    background: #999999;
}

* {
    box-sizing: border-box;
}

#__next {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

/* font */
pre {
    font-family: "Spoqa Han Sans Neo", sans-serif, NotoSansKr;
}

menu,
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

textarea,
input {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
